<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" :to="'/warehouses/view/'+this.$route.params.warehouseId">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <h1>Room: {{this.room.name}}</h1>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn v-if="isAllowed('room','u')" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-form :disabled="!isEditable">
        <v-row>
          <v-col>
            <v-text-field
                label="Name"
                outlined
                v-model="room.name"
            ></v-text-field>
            <v-text-field
                label="Description"
                outlined
                v-model="room.description"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row>
        <v-col v-if="isEditable">
          <v-btn @click="updateRoom">Save</v-btn>
          <v-btn v-if="isAllowed('room','d')" style="margin-left: 10px;" class="error" @click="deleteRoom">Delete</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <hr>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isAllowed('container','c')" cols="4">
          <v-container>
            <v-row>
              <v-col class='d-flex flex-row align-center'>
                <h1>Add New Container</h1>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Name"
                    outlined
                    v-model="cont.name"
                ></v-text-field>
                <v-text-field
                    label="Description"
                    outlined
                    v-model="cont.description"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn @click="createContainer" :disabled="!this.cont.name">Create</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
        <v-col>
          <v-container>
            <v-row>
              <v-col class="d-flex flex-row align-center">
                <h1>Containers</h1>
                <v-progress-circular
                    indeterminate
                    color="green"
                    v-if="loader"
                    style="margin-left: 10px;"
                ></v-progress-circular>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-data-table
                    :headers="containerHeaders"
                    :items="room.Containers"
                    :items-per-page="-1"
                    class="elevation-1"
                    @click:row="containerRowClick"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters} from "vuex";
  export default {
    data () {
      return {
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,

        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        containerHeaders: [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          { text: "Description", value: "description" }
        ],
        editMode: false,
        room: {},
        cont: {},
        isEditable: false,
      }
    },
    async mounted(){
      await this.getAllData();
    },
    computed: {
      ...mapGetters(['getEndpoint', "isAllowed"])
    },
    methods: {
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async updateRoom(){
        try {
          this.loader = true;
          let res = await axios.put(`${this.getEndpoint}/api/rooms/${this.$route.params.id}`, this.room)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack("Room info Updated 🎉", "success");
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      },
      async deleteRoom(){
        try {
          this.loader = true
          let res = await axios.delete(`${this.getEndpoint}/api/rooms/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack("Room Deleted 🎉", "success");

          await this.$router.push({ path: `/warehouses/view/${this.$route.params.warehouseId}`})
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false
        }
      },
      containerRowClick(container){
        this.$router.push({ path: `${this.$route.path}/containers/view/${container.id}`})
      },
      async createContainer(){
        try {
          let res = await axios.post(`${this.getEndpoint}/api/containers`, this.cont)
          if(res.data.error) throw res.data.error

          this.snack(`Container Created 🎉`, "success");
          this.cont={};
          await this.getAllData();
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        }
      },
      async getAllData(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/rooms/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.room = res.data.data
          this.cont.roomId=this.room.id;
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error");
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
